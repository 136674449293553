/// <reference path="../../typings/graphql.d.ts"/>

import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'

import Layout, { extractData, THEMES } from '../components/layout'
import { Page } from '../components/utils'

import Section from '../components/section'
import { Cinema, CINEMA_META } from '../model/model'
import createStore from '../model/create-store'
import { Provider } from 'react-redux';
import ConnectedUpdater from '../components/updater';

const Container = styled.div`
  flex-direction: column;
  margin: 0.5rem 0 0.5rem 0;
`

export const Title = styled.h3`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.highlight};
  font-weight: 300;
  text-transform: uppercase;
  text-align: left;
  padding-top: 5px;
  margin-bottom: 0.3rem;
`

interface PageProps {
  cinemas: Cinema[]
}


export const Question: React.SFC<{title: string}> = ({title, children}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}


const FAQPage: React.SFC<PageProps> = ( {cinemas} ) => (
  <Layout showBackButton={true} cinemas={cinemas} title="FAQ Covid e Cinema">
    <Page>
      <Section title="FAQ Covid e Cinema">
        <Question title="Per l'ingresso al cinema è necessario il Super Green Pass?">
          <p>
            Dal 6 Dicembre è necessario il Super Green Pass per accedere al cinema e verrà controllato all’ingresso del cinema tramite app dedicata. <br/>
          </p>
        </Question>
        <Question title="Come funziona il Super Green Pass?">
          <p>
            Il Super Green Pass si ottiene automaticamente in formato digitale o stampabile dopo:
            <ul>
              <li>aver fatto la vaccinazione anti COVID-19 (dopo la prima dose, valido dopo 15 giorni, sia al completamento del ciclo vaccinale)</li>
              <li>essere guariti dal COVID-19 negli ultimi sei mesi</li>
            </ul>
            Per maggiori informazioni sul rilascio del Super Green Pass vedi il <a href="https://www.dgc.gov.it/web/comeFunziona.html">sito ufficiale</a>.
          </p>
          Il Green Pass non è richiesto ai bambini fino a 11 anni compiuti e ai soggetti esenti sulla base di idonea certificazione medica.
          Per tali soggetti esenti verrà creata una certificazione digitale o cartacea dedicata.
          Per maggiori informazioni vai al <a href="https://www.dgc.gov.it/web/faq.html">sito ufficiale</a>.
        </Question>
        <Question title="I bambini possono entrare senza Super Green Pass?">
          I bambini fino a 12 anni non compiuti possono accedere senza Green Pass, dai 12 anni in poi è necessario per tutti.
        </Question>
        <Question title="Posso venire al cinema se ho fatto una sola dose del vaccino?">
          Per accedere al cinema bisogna presentare il Super Green Pass. <br/>
          Una volta fatta la prima dose del vaccino il Super Green Pass è valido dopo 15 giorni.
        </Question>
        <Question title="Basta il tampone negativo per entrare o ci vuole il Green Pass?">
          Non possiamo accettare il tampone negativo per l'ingresso, nè il Green Pass base che il tampone permette di generare. <br/>
          Per accedere al cinema bisogna avere il Super Green Pass, quindi essere vaccinati o guariti dal Covid.
        </Question>
        <Question title="È necessaria la prenotazione per venire al cinema? Dove posso comprare il biglietto?">
          Non è necessaria la prenotazione.<br/>
          È possibile comprare il biglietto online tramite il sito, oppure comprarlo direttamente in biglietteria. <br/>
          In qualunque caso è necessario avere il Super Green Pass al momento dell'accesso al cinema.
        </Question>
        <Question title="Ci sarà l'obbligo di portare la mascherina?">
          Si, la mascherina dovrà essere portata durante tutta la permanenza all'interno del cinema.
        </Question>
        <Question title="Come avverrà la disposizione dei posti?">
          L'attribuzione dei posti a sedere, sia se acquistati online che in biglietteria, avverrà nel rispetto delle norme di distanziamento previste dalla legge in vigore.
        </Question>
        <Question title="Familiari e conviventi potranno sedere vicino?">
          Si, come previsto dalle normativa.
        </Question>
        <Question title="Considerato il coprifuoco, a che ora finiranno gli spettacoli?">
          La programmazione terrà in considerazione il coprifuoco vigente al fine di consentire ai nostri spettatori la possibilità di tornare a casa.
        </Question>
      </Section>
    </Page>
  </Layout>
)

const StaticPage: React.SFC<any> = ( query ) => {
  const { cinemas, mainCinemaId } = extractData(query)
  const store = createStore("home", cinemas, mainCinemaId, false)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <FAQPage cinemas={cinemas}/>
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default StaticPage

export const query = graphql`
query FAQPageQuery {
  ...alldata
}
`
